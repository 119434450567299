<template>
  <section :class="$style.loyaltyProgram">
    <div :class="$style.wrapper">
      <div v-if="!isRemoteManager" :class="$style.buttons">
        <adw-button
          color="primary"
          :link="
            getRoute({
              route:
                $options.ADDWINE_ADMIN_ROUTES.CONTENT.LOYALTY_PROGRAM.CREATE,
            })
          "
        >
          Создать уровень
        </adw-button>
      </div>
    </div>
    <el-table :data="loyaltyPrograms" stripe>
      <el-table-column prop="name" label="Название"></el-table-column>
      <el-table-column
        prop="cashbackSize"
        label="Размер кэшбэка %"
      ></el-table-column>
      <el-table-column
        prop="requiredSum"
        label="Требуемая сумма (руб)"
      ></el-table-column>
      <el-table-column
        prop="expiredPeriod"
        label="Срок подтверждения (мес)"
      ></el-table-column>

      <el-table-column width="120">
        <template slot-scope="scope">
          <div :class="$style.btn">
            <ActionButtons
              :add-trash="false"
              :edit-link="getViewLinkById(scope.row.id)"
            />
            <ActionButtons
              :add-trash="true"
              @click.native="deleteLoyaltyProgram(scope.row.id)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import { ADDWINE_ADMIN_ROUTES, getRoute } from '@/constants/routing'
import delivery from '@/delivery'
import { mapGetters } from 'vuex'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  components: {
    ActionButtons,
  },
  ADDWINE_ADMIN_ROUTES,
  data() {
    return {
      loyaltyPrograms: [],
      limit: 20,
      page: 1,
      total: 0,
    }
  },
  computed: {
    ...mapGetters({
      isRemoteManager: 'isRemoteManager',
    }),
    totalPages() {
      return Math.ceil(this.total / this.limit)
    },
  },
  async created() {
    await this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
        orderDir: 'desc',
      }

      const { value, error } =
        await delivery.AddwineCore.LoyaltyProgramActions.getList(query)

      loading.close()

      if (error) return

      this.loyaltyPrograms = value.data
      this.total = value.meta.count
    },

    async deleteLoyaltyProgram(id) {
      const loading = this.$loading({
        lock: true,
      })

      const { error } = await delivery.AddwineCore.LoyaltyProgramActions.delete(
        id,
      )

      loading.close()

      if (error) {
        this.$message.error('Ошибка при удалении программы лояльности')
        return
      }

      this.$message.success('Программа лояльности успешно удалена')
      await this.getList()
    },
    getViewLinkById(id) {
      const routeObj = {
        route: ADDWINE_ADMIN_ROUTES.CONTENT.LOYALTY_PROGRAM.UPDATE,
        params: { id },
      }
      return this.getRoute(routeObj)
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
  },
}
</script>

<style lang="scss" module>
.loyaltyProgram {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    justify-content: space-between;
    margin-bottom: 1rem;

    .buttons {
      display: flex;
      gap: 3rem;
    }
  }

  .btn {
    display: flex;
  }

  .active {
    color: $complite;
  }

  .archive {
    color: $gray;
  }
  .pagination {
    @include pagination;
  }

  .link {
    text-decoration: none;
  }
}
</style>
